<template>
  <div class="beng_cont">
    <div class="img_cont">
      <span class="params params_const1">一次网参数</span>
      <span class="params params_const2">二次网参数</span>
<!--      <span class="params params_const3">泄水阀</span>-->
      <span class="params params_const4">补水泵：</span>
      <span class="params params_const4_1">补水泵：</span>
      <span class="params params_const5">液位：</span>
      <span class="params params_const6">补水阀</span>
      <span class="params params_const7">循环泵：</span>
      <span class="params params_const7_2">循环泵：</span>
      <span class="params params_input params_1">{{ paramsData["瞬时流量1_1"] ? paramsData["瞬时流量1_1"].value : "" }} {{
          paramsData["瞬时流量1_1"] ? paramsData["瞬时流量1_1"].pp_unit : ""
        }}</span>
      <span class="params params_input params_2">{{ paramsData["瞬时热量1_1"] ? paramsData["瞬时热量1_1"].value : "" }} {{
          paramsData["瞬时热量1_1"] ? paramsData["瞬时热量1_1"].pp_unit : ""
        }}</span>
      <span
          class="params params_input params_3">{{ paramsData["温度/供温1_1"] ? paramsData["温度/供温1_1"].value : "" }} {{
          paramsData["温度/供温1_1"] ? paramsData["温度/供温1_1"].pp_unit : ""
        }}</span>
      <span
          class="params params_input params_4">{{ paramsData["压力/回温1_1"] ? paramsData["压力/回温1_1"].value : "" }} {{
          paramsData["压力/回温1_1"] ? paramsData["压力/回温1_1"].pp_unit : ""
        }}</span>
      <span
          class="params params_input params_5">{{
          paramsData["一次供水压力1"] ? paramsData["一次供水压力1"].value : ""
        }} {{ paramsData["一次供水压力1"] ? paramsData["一次供水压力1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_6">{{
          paramsData["一次回水压力1"] ? paramsData["一次回水压力1"].value : ""
        }} {{ paramsData["一次回水压力1"] ? paramsData["一次回水压力1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_7">{{ paramsData["1#调节阀反馈"] ? paramsData["1#调节阀反馈"].value : "" }} {{
          paramsData["1#调节阀反馈"] ? paramsData["1#调节阀反馈"].pp_unit : ""
        }}</span>
      <span
          class="params params_input params_8">{{
          paramsData["二次供水温度1"] ? paramsData["二次供水温度1"].value : ""
        }} {{ paramsData["二次供水温度1"] ? paramsData["二次供水温度1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_9">{{
          paramsData["二次回水温度1"] ? paramsData["二次回水温度1"].value : ""
        }} {{ paramsData["二次回水温度1"] ? paramsData["二次回水温度1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_10">{{
          paramsData["二次供水压力1"] ? paramsData["二次供水压力1"].value : ""
        }} {{ paramsData["二次供水压力1"] ? paramsData["二次供水压力1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_11">{{
          paramsData["二次回水压力1"] ? paramsData["二次回水压力1"].value : ""
        }} {{ paramsData["二次回水压力1"] ? paramsData["二次回水压力1"].pp_unit : "" }}</span>
      <span v-if="paramsData.hasOwnProperty('循环泵运行状态1_1')"
            :class='{"params":true, "params_num":(paramsData["循环泵运行状态1_1"].value==1), "params_12":true,"params_num_red":true}'>{{
          paramsData["循环泵运行状态1_1"] ? paramsData["循环泵运行状态1_1"].value == 1 ? "启动" : "停止" : ""
        }}</span>
      <span v-if="paramsData.hasOwnProperty('循环泵运行状态1_2')"
            :class='{"params":true, "params_num":(paramsData["循环泵运行状态1_2"].value==1), "params_13":true,"params_num_red":true}'>{{
          paramsData["循环泵运行状态1_2"] ? paramsData["循环泵运行状态1_2"].value == 1 ? "启动" : "停止" : ""
        }}</span>
      <span v-if="paramsData.hasOwnProperty('循环泵运行状态1_3')"
            :class='{"params":true, "params_num":(paramsData["循环泵运行状态1_3"].value==1), "params_28":true,"params_num_red":true}'>{{
          paramsData["循环泵运行状态1_3"] ? paramsData["循环泵运行状态1_3"].value == 1 ? "启动" : "停止" : ""
        }}</span>
      <span v-if="paramsData.hasOwnProperty('补水泵运行状态1_1')"
            :class='{"params":true, "params_num":(paramsData["补水泵运行状态1_1"].value==1), "params_15":true,"params_num_red":true}'>{{
          paramsData["补水泵运行状态1_1"] ? paramsData["补水泵运行状态1_1"].value == 1 ? "启动" : "停止" : ""
        }}</span>
      <span v-if="paramsData.hasOwnProperty('补水泵运行状态1_2')"
            :class='{"params":true, "params_num":(paramsData["补水泵运行状态1_2"].value==1), "params_16":true,"params_num_red":true}'>{{
          paramsData["补水泵运行状态1_2"] ? paramsData["补水泵运行状态1_2"].value == 1 ? "启动" : "停止" : ""
        }}</span>
      <span class="params params_input params_17">{{ paramsData["1#水箱液位"] ? paramsData["1#水箱液位"].value : "" }} {{
          paramsData["1#水箱液位"] ? paramsData["1#水箱液位"].pp_unit : ""
        }}</span>
      <span
          class="params params_input params_18">{{
          paramsData["补水累计流量1"] ? paramsData["补水累计流量1"].value : ""
        }} {{ paramsData["补水累计流量1"] ? paramsData["补水累计流量1"].pp_unit : "" }}</span>
      <span
          class="params params_input params_19">{{ paramsData["2#调节阀反馈"] ? paramsData["2#调节阀反馈"].value : "" }} {{
          paramsData["2#调节阀反馈"] ? paramsData["2#调节阀反馈"].pp_unit : ""
        }}</span>
      <span v-if="paramsData.hasOwnProperty('循环泵运行状态2_1')"
            :class='{"params":true, "params_num":(paramsData["循环泵运行状态2_1"].value==1), "params_20":true,"params_num_red":true}'>{{
          paramsData["循环泵运行状态2_1"] ? paramsData["循环泵运行状态2_1"].value == 1 ? "启动" : "停止" : ""
        }}</span>
      <span v-if="paramsData.hasOwnProperty('循环泵运行状态2_2')"
            :class='{"params":true, "params_num":(paramsData["循环泵运行状态2_2"].value==1), "params_21":true,"params_num_red":true}'>{{
          paramsData["循环泵运行状态2_2"] ? paramsData["循环泵运行状态2_2"].value == 1 ? "启动" : "停止" : ""
        }}</span>
      <span v-if="paramsData.hasOwnProperty('循环泵运行状态2_3')"
            :class='{"params":true, "params_num":(paramsData["循环泵运行状态2_3"].value==1), "params_29":true,"params_num_red":true}'>{{
          paramsData["循环泵运行状态2_3"] ? paramsData["循环泵运行状态2_3"].value == 1 ? "启动" : "停止" : ""
        }}</span>
      <span v-if="paramsData.hasOwnProperty('补水泵运行状态2_1')"
            :class='{"params":true, "params_num":(paramsData["补水泵运行状态2_1"].value==1), "params_22":true,"params_num_red":true}'>{{
          paramsData["补水泵运行状态2_1"] ? paramsData["补水泵运行状态2_1"].value == 1 ? "启动" : "停止" : ""
        }}</span>
      <span v-if="paramsData.hasOwnProperty('补水泵运行状态2_2')"
            :class='{"params":true, "params_num":(paramsData["补水泵运行状态2_2"].value==1), "params_23":true,"params_num_red":true}'>{{
          paramsData["补水泵运行状态2_2"] ? paramsData["补水泵运行状态2_2"].value == 1 ? "启动" : "停止" : ""
        }}</span>
      <span
          class="params params_input params_24">{{
          paramsData["二次供水压力2"] ? paramsData["二次供水压力2"].value : ""
        }} {{ paramsData["二次供水压力2"] ? paramsData["二次供水压力2"].pp_unit : "" }}</span>
      <span
          class="params params_input params_25">{{
          paramsData["二次供水温度2"] ? paramsData["二次供水温度2"].value : ""
        }} {{ paramsData["二次供水温度2"] ? paramsData["二次供水温度2"].pp_unit : "" }}</span>
      <span
          class="params params_input params_26">{{
          paramsData["二次回水压力2"] ? paramsData["二次回水压力2"].value : ""
        }} {{ paramsData["二次回水压力2"] ? paramsData["二次回水压力2"].pp_unit : "" }}</span>
      <span
          class="params params_input params_27">{{
          paramsData["二次回水温度2"] ? paramsData["二次回水温度2"].value : ""
        }} {{ paramsData["二次回水温度2"] ? paramsData["二次回水温度2"].pp_unit : "" }}</span>
    </div>
  </div>
</template>

<script>
//2个泵
export default {
  name: "beng2_3",
  data() {
    return {
      paramsList: [],
      paramsData: {},
      device_id: ""
    }
  },
  mounted() {
    this.$axios.defaults.headers.common["Authorization"] = "bearer " + this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.device_id = this.$route.query.id;
    this.getParamsData();
    setInterval(() => {
      this.getParamsData();
    }, 10000)

  },
  methods: {
    //获取参数的实时数据
    getParamsData: function () {
      var url = '/heating/zeiot/device/read-time?id=' + this.device_id;
      this.$axios.get(url).then((res) => {
        if (res.status == 200) {
          this.paramsData = {};
          res.data.forEach((item) => {
            this.paramsData[item.pp_name] = {
              value: item.pp_value,
              pp_unit: item.pp_unit
            }
          })
        }
      }).catch((res) => {
        console.log(res);
      })
    }

  }
}
</script>

<style scoped>
.beng_cont {
  width: 100%;
  height: 100%;
  padding: 0 0.1rem 0.1rem;
  box-sizing: border-box;
}

.img_cont {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/beng/2_3.png") center/100% 100% no-repeat;
  position: relative;
}

.params {
  position: absolute;
  font-size: 12px;
}

.params_input_tit {
  color: #ffffff;
  display: flex;
  align-items: center;
}

.params_input {
  display: inline-block;
  width: 60px;
  height: 24px;
  line-height: 24px;
  /* box-shadow: 0.01rem 0.01rem 1px 1px #CCCCCC ;*/
  background-color: transparent;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  overflow: hidden;
}

.params_num_red {
  color: red;
}

.params_num {
  color: #00fc00;
}

.params_1 {
  top: 0.25rem;
  left: 0.73rem;
}

.params_2 {
  top: 0.5rem;
  left: 0.73rem;
}

.params_3 {
  top: 1rem;
  left: 2.25rem;
}

.params_4 {
  bottom: 1.75rem;
  left: 2.24rem;
}

.params_5 {
  top: 1rem;
  left: 1.45rem;
}

.params_6 {
  bottom: 1.75rem;
  left: 1.45rem;
}

.params_7 {
  top: 1rem;
  left: 4.05rem;
}

.params_8 {
  top: 0.6rem;
  right: 1.9rem;
}

.params_9 {
  top: 1.4rem;
  right: 0.005rem;
}

.params_10 {
  top: 0.6rem;
  right: 2.9rem;
}

.params_11 {
  top: 1.4rem;
  right: 0.9rem;
}

.params_12 {
  top: 1.36rem;
  right: 3.6rem
}

.params_13 {
  top: 1.8rem;
  right: 3.6rem;
}

.params_15 {
  top: 2.25rem;
  right: 2.2rem;
}

.params_16 {
  top: 2.8rem;
  right: 2.2rem;
}

.params_17 {
  bottom: 1.35rem;
  left: 4.2rem;
}

.params_18 {
  bottom: 0.7rem;
  left: 1.05rem;
}
.params_19 {
  bottom: 2.5rem;
  left: 4.05rem;
}

.params_20 {
  bottom: 2.2rem;
  right: 3.75rem;
}

.params_21 {
  bottom: 1.75rem;
  right: 3.75rem;
}

.params_22 {
  bottom: 1.35rem;
  right: 2.25rem;
}

.params_23 {
  bottom: 0.85rem;
  right: 2.25rem;
}

.params_24 {
  bottom: 2.9rem;
  right: 0.9rem;
}

.params_25 {
  bottom: 2.9rem;
  right: 0;
}

.params_26 {
  bottom: 2.15rem;
  right: 0.9rem;
}

.params_27 {
  bottom: 2.15rem;
  right: 0;
}
.params_28 {
  top: 2.22rem;
  right: 3.6rem
}
.params_29 {
  bottom: 1.34rem;
  right: 3.75rem;
}

.params_const1 {
  top: 1.061rem;
  left: 0.6rem;
  color: #00fc00;
}

.params_const2 {
  top: 1.06rem;
  right: 0.6rem;
  color: #00fc00;
}

.params_const3 {
  top: 3.45rem;
  right: 0.5rem;
  color: #ffffff;
}

.params_const4 {
  top: 2.8rem;
  right: 2.7rem;
  color: #ffffff;
}
.params_const4_1 {
  bottom: 0.8rem;
  right: 2.7rem;
  color: #ffffff;
}

.params_const5 {
  bottom: 1.38rem;
  left: 4rem;
  color: #ffffff;
}

.params_const6 {
  bottom: 0.7rem;
  left: 3.3rem;
  color: #ffffff;
}

.params_const7 {
  top: 2.2rem;
  right: 4.28rem;
  color: #ffffff;
}
.params_const7_2 {
  bottom: 1.37rem;
  right: 4.37rem;
  color: #ffffff;
}
</style>