<template>
<div class="page">
  <breadcrumb :crumbs="crumbs"></breadcrumb>
  <div class="pageCont">
    <div class="searchCont">
      <div class="searchItem">
        <span class="searchTit"></span>
        <a-input v-model:value="keyword" autofocus placeholder="输入关键字" />
      </div>
      <div class="searchItem">
        <a-button type="primary" class="searchBtn" @click="search">查询</a-button>
      </div>
      <div class="searchItem">
        <a-button type="primary" class="searchBtn" @click="addFun">添加</a-button>
      </div>
      <div class="searchItem">
        <a-button type="danger" class="searchBtn" @click="batchDel">批量删除</a-button>
      </div>
      <div class="searchCont_r">
        <div class="searchItem">
          <a-button  class="searchBtn" @click="exportFun">导出excel</a-button>
        </div>
        <div class="searchItem">
          <a-button  class="searchBtn" ><a href="./template/单元阀模板.xlsx" download="单元阀模板.xlsx">下载模板</a></a-button>
        </div>
        <div class="searchItem">
          <a-upload
              name="file"
              action=""
              :customRequest="uploadFun"
          >
            <a-button type="primary" class="searchBtn" >导入Excel文件</a-button>
          </a-upload>
        </div>
      </div>
    </div>
    <a-table class="m_t_2" :columns="columns" :data-source="myData" :pagination="false" :scroll="{ y: scroll_y }" :row-selection="{selectedRowKeys: selectedRowKeys,onChange:onSelectKey}">
      <template #bodyCell="{ column, record,index }">
        <template v-if="column.dataIndex === 'caozuo'">
          <a-button type="link" @click="editFun(index)">编辑</a-button>
          <a-popconfirm title="是否确定删除此项?" ok-text="是"
                        cancel-text="否" @confirm="delFun(record)">
            <a-button type="link" danger>删除</a-button>
          </a-popconfirm>
        </template>
      </template>
    </a-table>
        <a-pagination class="pagination pages_box" v-model:current="pageIndex" :pageSize="pageSize"  :total="myDataNum" show-less-items size="small" show-size-changer @change="onShowSizeChange" />
  </div>

  <a-modal v-model:visible="editModal" :title="tantitle" :width="400" :maskClosable="false" :footer="null">
    <div class="modalForm">
      <a-form ref="formM" :model="editData" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
        <a-form-item label="小区" name="unitvalve_community_id"  :rules="[{ required: true, message: '请选择!' }]">
          <a-select
              v-model:value="editData.unitvalve_community_id"
              show-search
              placeholder="选择小区"
              :options="xqList"
              :disabled="tantitle=='修改'?true:false"
              :field-names='{"label":"community_name","value":"community_id"}'
              :filter-option="filterXQ" @change="changeXQ"></a-select>
        </a-form-item>
        <a-form-item label="单元阀名称" name="unitvalve_name" :rules="[{ required: true, message: '请输入!' }]">
          <a-input v-model:value="editData.unitvalve_name"/>
        </a-form-item>
<!--        <a-form-item label="单元阀编号" name="unitvalve_code" :rules="[{ required: true, message: '请输入!' }]">
          <a-input v-model:value="editData.unitvalve_code"/>
        </a-form-item>-->
        <a-form-item label="单元阀序号" name="unitvalve_sort" :rules="[{ required: true, message: '请输入!' }]">
          <a-input v-model:value="editData.unitvalve_sort"/>
        </a-form-item>
        <a-form-item label="模组imei" name="unitvalve_imei" :rules="[{ required: true, message: '请输入!' }]">
          <a-input v-model:value="editData.unitvalve_imei"/>
        </a-form-item>
        <a-form-item label="分区" name="unitvalve_partition_id" :rules="[{ required: true, message: '请选择!' }]">
          <a-select
              v-model:value="editData.unitvalve_partition_id"
              show-search
              placeholder="选择分区"
              :options="partList_model"
              :field-names='{"label":"device_model_partition_name","value":"device_model_partition_id"}'
              :filter-option="filterFQ"></a-select>
        </a-form-item>
<!--        <a-form-item label="换热站" name="station_name" :rules="[{ required: true, message: '请选择!' }]">
          <a-select
              v-model:value="editData.device_id"
              show-search
              placeholder="选择型号"
              style="width: 200px"
              :options="deviceList"
              :field-names='{"label":"device_name","value":"device_id"}'
              :filter-option="filterOption2"></a-select>
        </a-form-item>-->
      </a-form>
    </div>
    <div class="modelFooter">
      <div class="modal_btns">
        <a-button type="primary" class="modal_btn" size="middle" @click="save">保存</a-button>
      </div>
    </div>
  </a-modal>
</div>
</template>

<script>
export default {
  name: "unitValveManagement",
  data(){
    return {
      crumbs:["单元阀管理"],
      keyword:"",
      pageIndex:1,
      pageSize:10,
      myData:[],
      myDataNum:0,
      columns:[
        { title: '小区', dataIndex: 'community_name', key: 'community_name',align:"center" },
        { title: '单元阀名称', dataIndex: 'unitvalve_name', key: 'unitvalve_name',align:"center" },
        { title: '模组imei', dataIndex: 'unitvalve_imei', key: 'unitvalve_imei',align:"center" },
        { title: '分区', dataIndex: 'device_model_partition_name', key: 'device_model_partition_name',align:"center" },
        { title: '操作', dataIndex: 'caozuo', key: 'caozuo',align:"center" },
      ],
      editModal:false,
      tantitle:"添加",
      editData:{
        "unitvalve_community_id": "",
        "unitvalve_device_id": "",
        "unitvalve_partition_id": "",
        "unitvalve_name": "",
        "unitvalve_code": "",
        "unitvalve_imei": "",
        "unitvalve_model_id": "",
        "unitvalve_sort": 0
      },
      xqList:[],
      partList:[],
      partList_model:[],
      dpObj:{},
      scroll_y:300,
      selectedRowKeys:[],
      del_index:0,
    }
  },
  mounted(){
    this.scroll_y = document.querySelectorAll(".page")[0].offsetHeight- 220;
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.getXQList();
    this.getDpList();
  },
  methods:{
    getData(){
      var url = "/heating/device-unitvalve/info/page";
      this.$axios.post(url,{
        keyword:this.keyword,
        pageNo:this.pageIndex,
        pageSize:this.pageSize,
        community_id:"",
        zone_id:""
      }).then((res)=>{
        if(res.status == 200){
          this.myData = res.data.data;
          this.myData.forEach((item)=>{
            item.device_model_partition_name = "";
            item.unitvalve_community_id = item.community_id;
            item.key = item.unitvalve_id;
            this.partList.some((val)=>{
              if(val.device_model_partition_id == item.unitvalve_partition_id){
                item.device_model_partition_name = val.device_model_partition_name
                return true
              }
            })
          })
          this.myDataNum = res.data.totalItems;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    addFun(){
      this.tantitle = "添加";
      this.editModal = true;
    },
    editFun(index){
      this.tantitle = "修改";
      this.editModal = true;
      this.editData = Object.assign({},this.myData[index]);
      this.changeXQ()
    },
    save(){
      this.$refs.formM.validateFields().then(() => {
        var url = "";
        if(this.tantitle=="添加"){
          url = "/heating/device-unitvalve/add";
          var data = JSON.parse(JSON.stringify(this.editData))
          data.unitvalve_code = "";
          this.$axios.post(url,data).then((res)=>{
            if(res.status == 200){
              this.$showSuccess("保存成功！");
              this.getData();
              this.editModal = false;
            }
          }).catch((res)=>{
            console.log(res)
          })
        }else{
          url = "/heating/device-unitvalve/up";
          var data = JSON.parse(JSON.stringify(this.editData))
          this.$axios.put(url,data).then((res)=>{
            if(res.status == 200){
              this.$showSuccess("保存成功！");
              this.getData();
              this.editModal = false;
            }
          }).catch((res)=>{
            console.log(res)
          })
        }
      }, (err) => {
        console.log(err)
      })
    },
    //删除
    delFun(record){
      var url = "/heating/device-unitvalve/remove?id="+record.unitvalve_id;
      var data = JSON.parse(JSON.stringify(this.editData))
      this.$axios.delete(url,data).then((res)=>{
        if(res.status == 200){
          this.$showSuccess("删除成功！");
          this.getData();
        }
      }).catch((res)=>{
        console.log(res)
      })
    },
    onShowSizeChange(page,pageSize) {
      this.pageIndex = page;
      this.pageSize = pageSize;
      this.myData = [];
      this.myDataNum = 0;
      this.getData();
    },
    search(){
      this.pageIndex = 1;
      this.myDataNum = 0;
      this.myData =[];
      this.getData();
    },
    //获取小区列表
    getXQList(){
      /*var url = "/heating/community/info";

      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.xqList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })*/
      var url = "/heating/community/bind/device/info";

      this.$axios.post(url,{
        "zone_id": "",
        "community_id": ""
      }).then((res)=>{
        if(res.status == 200){
          this.xqList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterXQ(e,option){
      return option.community_name.includes(e);
    },
    //选择小区
    changeXQ(){
      var device_id = "";
      this.xqList.forEach((item)=>{
        if(item.community_id == this.editData.unitvalve_community_id){
          device_id = item.cbd_device_id;
          this.getDeviceInfo(device_id)
        }
      })
      this.unitvalve_partition_id = "";
    },
    //获取设备信息
    getDeviceInfo(id){
      var url = '/api/user/devices/'+id;
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          var dp_id = res.data.device_dp_id;
          this.partList_model = [];
          this.partList.forEach((item)=>{
            if(item.device_model_id == dp_id){
              this.partList_model.push(item)
            }
          })
        }
      })
    },
    //获取分区列表
    getPartitionList(){
      var url = "/heating/device/part";
      /*var url = "/heating/community/info/page";*/
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.partList = res.data;
          this.partList.forEach((item)=>{
            if(this.dpObj[item.device_model_id]){
              item.device_model_partition_name = item.device_model_partition_name+"("+this.dpObj[item.device_model_id].dp_name+")";
            }
          })
          this.getData();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterFQ(e,option){
      return option.device_model_partition_name.includes(e);
    },
    //获取型号列表
    getDpList(){
      var url = "/heating/zeiot/device-protocols-options";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.dpObj = {};
          res.data.forEach((item)=>{
            this.dpObj[item.dp_id] = item;
          })
          this.getPartitionList();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    saveBath(data){
      if(data.length<=0){
        return false
      }
      var url = "/heating/device-unitvalve/import";
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.$showSuccess("导入成功！");
          this.getData();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //批量删除
    batchDel(){
      if(this.selectedRowKeys.length<=0){
        return this.$showWarmTip("至少选择一项！");
      }
      this.del_index = 0;
      this.selectedRowKeys.forEach((item)=>{
        this.del_fun(item);
      })
    },
    del_fun(id){
      var url = "/heating/device-unitvalve/remove?id="+id;
      this.$axios.delete(url).then((res)=>{
        this.del_index = this.del_index+1;
        if(res.status == 200){
        }
        if(this.del_index == this.selectedRowKeys.length){
          this.selectedRowKeys = [];
          this.getData();
        }
      }).catch((res)=>{
        this.del_index = this.del_index+1;
        if(this.del_index == this.selectedRowKeys.length){
          this.selectedRowKeys = [];
          this.getData();
        }
        console.log(res);
      })
    },
    onSelectKey(a,b){
      this.selectedRowKeys = a;
    },
    //导出
    exportFun(){
      var headers = {
        "小区":"小区",
        "单元阀名称":"单元阀名称",
        "模组imei":"模组imei",
        "分区":"分区",
      };
      var newData = [];
      var url = "/heating/device-unitvalve/info";
      this.$axios.post(url,{
        "zone_id": "",
        "community_id":"",
        "keyword": this.keyword,
      }).then((res)=>{
        if(res.status == 200){
          var Data = res.data;
          Data.forEach((item)=>{
            this.partList.some((val)=>{
              if(val.device_model_partition_id == item.unitvalve_partition_id){
                item.device_model_partition_name = val.device_model_partition_name
                return true
              }
            })
            newData.push({
              "小区":item.community_name,
              "单元阀名称":item.unitvalve_name,
              "模组imei":item.unitvalve_imei,
              "分区":item.device_model_partition_name,
            })
          })
          this.$exportExcel(newData,headers,"单元阀管理")
        }
      }).catch((res)=>{

      })

    },
    //导入
    uploadFun(e){
      this.$uploadExcel(e.file).then((obj)=>{
        var data = [];
        obj.data.forEach((item)=>{
          data.push({
            "zone_name": item["区域名称"]?item["区域名称"].toString():"",
            "community_name": item["小区名称"]? item["小区名称"].toString():"",
            "unitvalve_name": item["单元阀名称"]?item["单元阀名称"].toString():"",
            "unitvalve_code": item["模组imei"]?item["模组imei"].toString():"",
            /*"unitvalve_model_name": item["单元阀类型"]?item["单元阀类型"].toString():"",*/
            "device_code": item["换热站设备编码"]?item["换热站设备编码"].toString():"",
            "device_model_partition_name": item["分区名称"]?item["分区名称"].toString():"",
          })
        })
        this.uploadExcel(data);
      })
      return
    },
    //导入单元阀
    uploadExcel(DATA){
      var url = "/heating/device-unitvalve/import";
      var data = DATA;
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.$showSuccess("导入成功！");
          this.search();
        }
      }).catch((res)=>{
        console.log(res);
      })
    }

  }
}
</script>

<style scoped>

</style>