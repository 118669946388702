<template>
<div>
  <iframe :src="monitor_url" frameborder="none" scrolling="none"></iframe>
</div>
</template>

<script>
export default {
  name: "monitor_3d",
  data(){
    return {
      monitor_id:"",
      beng_type:"",
      monitor_url:"",
      device_id:""
    }
  },
  mounted(){
    this.beng_type = this.$route.query.beng_type;
    this.device_id = this.$route.query.id;
    this.monitor_id = this.$route.query.m3_i;
    var ip = this.$axios_ip.defaults.baseURL;
    if(this.beng_type == "monitor_template"){
      this.monitor_url = "";
      var zeiot_token = this.$cookie.get("zeiot_token");
      this.monitor_url = ip+'/template/system/canvas-template-plus/preview.html?monitor_id='+this.monitor_id+'&token='+zeiot_token+'&system_id='+this.$cookie.get("user_system_id")+'&project_id=null&MenuId=1&user_id='+this.$cookie.get("user_id");
    }
    if(this.beng_type=="monitor_other"){
      this.monitor_url = ip+'/template/project/monitoring-list/simulated_scene.html?monitor_id=' + this.monitor_id + '&deviceID='+this.device_id+'&MenuId=1' + '&salt=' + this.$cookie.get("user_salt");
    }

  },
  methods:{

  }
}
</script>

<style scoped>

</style>