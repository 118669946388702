<template>
  <div class="page">
    <breadcrumb :crumbs="crumbs"></breadcrumb>
    <div class="pageCont">
      <div class="searchCont">
        <div class="searchItem">
          <span class="searchTit"></span>
          <a-input v-model:value="keyword" autofocus placeholder="输入关键字" />
        </div>
        <div class="searchItem">
          <a-button type="primary" class="searchBtn" @click="search">查询</a-button>
        </div>
        <div class="searchItem">
          <a-button type="primary" class="searchBtn" @click="addReport">添加</a-button>
        </div>
      </div>

      <a-table class="m_t_2" :columns="columns" :data-source="myData" :pagination="false">
        <template #bodyCell="{ column, record,index }">
          <template v-if="column.dataIndex === 'head'">
            <a-button type="link" @click="showSetHead(index)">设置</a-button>
          </template>
          <template v-if="column.dataIndex === 'content'">
            <a-button type="link" @click="showSetContent(index)">设置</a-button>
          </template>
          <template v-if="column.dataIndex === 'caozuo'">
            <a-button type="link" @click="showEdit(index)">编辑</a-button>
            <a-popconfirm title="是否确定删除此项?" ok-text="是"
                          cancel-text="否" @confirm="delFun(index)">
              <a-button type="link" danger>删除</a-button>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
      <a-pagination class="pagination pages_box" v-model:current="pageIndex" :pageSize="pageSize"  :total="myDataNum" show-less-items size="small" show-size-changer @change="onShowSizeChange" />
      <a-modal v-model:visible="edit_modal" :title="tantitle" :width="400" :maskClosable="false" :footer="null">
        <div class="modalForm">
          <a-form ref="formM" :model="editData" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
            <a-form-item label="排序" name="station_sort" >
              <a-input v-model:value="editData.station_sort"/>
            </a-form-item>
            <a-form-item label="名称" name="station_name" :rules="[{ required: true, message: '请输入名称!' }]">
              <a-input v-model:value="editData.station_name"/>
            </a-form-item>
          </a-form>
        </div>
        <div class="modelFooter">
          <div class="modal_btns">
            <a-button type="primary" class="modal_btn" size="middle" @click="confirm_save">保存</a-button>
          </div>
        </div>
      </a-modal>

      <!--  表头  -->
      <a-modal v-model:visible="set_head_modal" :title="report_name" :width="600" :maskClosable="false" :footer="null">
        <div class="modalForm">
          <a-form ref="formM" :model="station_header_data"  :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
            <a-form-item label="操作级别" name="map_line_name">
              <a-select ref="select"  style="width: 200px" v-model:value="station_header_data.czjb" >
                <a-select-option :value="item" v-for="(item,index) in czjb" :key="index">{{ item }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="型号" name="dp_ids" :rules="[{ required: true, message: '请输入名称!' }]">
              <a-select
                  v-model:value="station_header_data.dp_ids"
                  show-search
                  placeholder="选择型号"
                  style="width: 208px"
                  :options="wlwDevList"
                  mode="multiple"
                  :fieldNames="{label:'dp_name',value:'dp_id'}"
              ></a-select>
            </a-form-item>
            <a-form-item label="流程图" name="map_line_name">
              <a-select ref="select"  style="width: 200px" v-model:value="station_header_data.lct"   >
                <a-select-option value="1">监控点</a-select-option>
                <a-select-option value="2">智慧供热监控系统</a-select-option>
              </a-select>
            </a-form-item>
            <div>一次网参数 <a-button type="primary" class="searchBtn" @click="addHead(1)" size="small">添加</a-button></div>
            <a-table class="m_t_2" :columns="columns_head" :data-source="station_header_data.oneNetwork" :pagination="false">
              <template #bodyCell="{ column, record,index }">
                <template v-if="column.dataIndex === 'caozuo'">
                  <form-outlined style="color: green" @click="editHead(1,index)" />
                  <delete-outlined style="color: red" @click="delHead(1,index)" class="m_l_1" />
                </template>
                <template v-if="column.dataIndex === 'type'">
                  {{record.type == "计算"?"计算":"数值"}}
                </template>
              </template>
            </a-table>
            <div class="m_t_1">阀位 <a-button type="primary" class="searchBtn" v-if="station_header_data.valvePos.length<1" @click="addHead(3)" size="small">添加</a-button></div>
            <a-table class="m_t_2" :columns="columns_head" :data-source="station_header_data.valvePos" :pagination="false">
              <template #bodyCell="{ column, record,index }">
                <template v-if="column.dataIndex === 'caozuo'">
                  <form-outlined style="color: green" @click="editHead(3,index)" />
                  <delete-outlined style="color: red" @click="delHead(3,index)" class="m_l_1" />
                </template>
                <template v-if="column.dataIndex === 'type'">
                  {{record.type == "计算"?"计算":"数值"}}
                </template>
              </template>
            </a-table>
            <div class="m_t_1">二次网参数 <a-button type="primary" class="searchBtn" @click="addHead(2)" size="small">添加</a-button></div>
            <a-table class="m_t_2" :columns="columns_head" :data-source="station_header_data.secNetwork" :pagination="false">
              <template #bodyCell="{ column, record,index }">
                <template v-if="column.dataIndex === 'caozuo'">
                  <form-outlined style="color: green" @click="editHead(2,index)" />
                  <delete-outlined style="color: red" @click="delHead(2,index)" class="m_l_1" />
                </template>
                <template v-if="column.dataIndex === 'type'">
                  {{record.type == "计算"?"计算":"数值"}}
                </template>
              </template>
            </a-table>

            <div>扩展属性</div>
            <div>
              <a-checkbox-group v-model:value="station_header_data.extData" style="width: 100%;display: flex;flex-wrap: wrap">
                <div v-for="(item,index) in extList" :key="index" style="width:33.33%;margin: 4px 0">
                  <a-checkbox :value="item.etc_column_header">{{ item.etc_column_header }}</a-checkbox>
                </div>

              </a-checkbox-group>
            </div>

          </a-form>
        </div>
        <div class="modelFooter">
          <div class="modal_btns">
            <a-button type="primary" class="modal_btn" size="middle" @click="saveSetHead">保存</a-button>
          </div>
        </div>
      </a-modal>

      <a-modal v-model:visible="head_modal" :title="head_name==1?'一次网参数':head_name==2?'二次网参数':'阀位'" :width="400" :maskClosable="false" :footer="null">
        <div class="modalForm">
          <a-form ref="formM" :model="headData" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
            <a-form-item label="名称" name="name" :rules="[{ required: true, message: '请输入名称!' }]">
              <a-input v-model:value="headData.name"/>
            </a-form-item>
            <a-form-item label="单位" name="unit" >
              <a-input v-model:value="headData.unit"/>
            </a-form-item>
            <a-form-item label="类型" name="type" >
              <a-select ref="select"  v-model:value="headData.type" @change="changeTypeFun" >
                <a-select-option value="数值">数值</a-select-option>
                <a-select-option value="计算">计算</a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </div>
        <div class="modelFooter">
          <div class="modal_btns">
            <a-button type="primary" class="modal_btn" size="middle" @click="saveHead">保存</a-button>
          </div>
        </div>
      </a-modal>
      <!--  表内容  -->
      <a-modal v-model:visible="set_content_modal" :title="report_name" :width="1000" :maskClosable="false" :footer="null">
        <div class="modalForm2" style="overflow: auto">
          <table style="width: 100%" class="report_table" v-if="set_content_modal">
            <thead>
            <tr>
              <th rowspan="2" ><div style="width: 100px">型号名称</div></th>
              <th rowspan="2" ><div style="width: 60px">分区</div></th>
              <th v-if="station_header_data.oneNetwork.length" :colspan="station_header_data.oneNetwork.length">一次网参数</th>
              <th rowspan="2" v-if="station_header_data.valvePos.length" >阀位</th>
              <th v-if="station_header_data.secNetwork.length" :colspan="station_header_data.secNetwork.length" >二次网参数</th>

              <!--                    <th rowspan="2" v-for="(item,index) in station_header_data.extData" :key="index" >{{ item }}</th>-->
            </tr>
            <tr>
              <template v-for="(item,index) in station_header_data.oneNetwork" :key="index">
                <th>{{ item.name }} {{item.unit?("("+item.unit+")"):""}}</th>
              </template>
              <template v-for="(item,index) in station_header_data.secNetwork" :key="index">
                <th>{{ item.name }} {{item.unit?("("+item.unit+")"):""}}</th>
              </template>
            </tr>
            </thead>
            <tbody>
            <template v-for="(item,index) in save_dp_List" :key="index">
              <tr v-for="(val,key) in item.fenqu" :key="key">
                <td style="text-align: center" :rowspan="item.fenqu.length?item.fenqu.length:1" v-if="key ==0">{{item.dp_name}}</td>
                <td style="text-align: center" >{{ val }}</td>
                <template v-if="key ==0">
                  <td style="text-align: center" :rowspan="item.fenqu?item.fenqu.length:1"  v-for="(v,k) in station_header_data.oneNetwork" :key="k">
                    <a-input v-model:value="item.oneNetwork[k].calc[key]" v-if="item.oneNetwork[k].type=='计算'" style="width: 100px"/>
                    <a-select v-model:value="item.oneNetwork[k].pp_id[key]"
                              show-search
                              placeholder="选择参数"
                              style="width: 100px"
                              :filter-option="filterOption"
                              :fieldNames="{'label':'dpp_name','value':'dpp_id'}"
                              :options="item.params" v-else></a-select>
                  </td>
                </template>
                <template v-for="(v,k) in station_header_data.valvePos" :key="k">
                  <td style="text-align: center" :rowspan="item.fenqu?item.fenqu.length:1" v-if="key ==0">
                    <a-input v-model:value="item.valvePos[k].calc[key]" v-if="item.valvePos[k].type=='计算'" style="width: 100px"/>
                    <a-select v-model:value="item.valvePos[k].pp_id[key]"
                              show-search
                              placeholder="选择参数"
                              style="width: 100px"
                              :filter-option="filterOption"
                              :fieldNames="{'label':'dpp_name','value':'dpp_id'}"
                              :options="item.params"></a-select>
                  </td>
                </template>
                <template v-for="(v,k) in station_header_data.secNetwork" :key="k">
                  <td style="text-align: center">
                    <a-input v-model:value="item.secNetwork[k].calc[key]" v-if="item.secNetwork[k].type=='计算'" style="width: 100px"/>
                    <a-select v-model:value="item.secNetwork[k].pp_id[key]"
                              show-search
                              placeholder="选择参数"
                              style="width: 100px"
                              :filter-option="filterOption"
                              :fieldNames="{'label':'dpp_name','value':'dpp_id'}"
                              :options="item.params" v-else></a-select>
                  </td>
                </template>
                <!--                      <template v-for="(v,k) in station_header_data.extData" :key="k">
                                        <td style="text-align: center" :rowspan="item.fenqu?item.fenqu.length:1" v-if="key ==0">
                                          <div style="width:80px"></div>
                                        </td>
                                      </template>-->
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <div class="modelFooter">
          <div class="modal_btns">
            <a-button type="primary" class="modal_btn" size="middle" @click="saveSetContent">保存</a-button>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return {
      crumbs:["报表管理"],
      keyword:"",
      columns:[
        { title: '序号', dataIndex: 'station_sort', key: 'station_sort',align:"center" },
        { title: '报表名称', dataIndex: 'station_name', key: 'station_name',align:"center" },
        { title: '表头', dataIndex: 'head', key: 'head',align:"center" },
        { title: '表内容', dataIndex: 'content', key: 'content',align:"center" },
        { title: '操作', dataIndex: 'caozuo', key: 'caozuo',align:"center" },
      ],
      myData:[],
      myDataNum:0,
      pageIndex:1,
      pageSize:10,
      edit_modal:false,
      tantitle:"添加",
      editData:{
        "station_name": "",
        "station_sort": 0,
        "station_header_data": "",
        "station_data": "",
      },
      set_content_modal:false,
      report_name:"1",
      set_head_modal:false,
      columns_head:[
        { title: '表头名称', dataIndex: 'name', key: 'name',align:"center" },
        { title: '单位', dataIndex: 'unit', key: 'unit',align:"center" },
        { title: '类型', dataIndex: 'type', key: 'type',align:"center" },
        { title: '操作', dataIndex: 'caozuo', key: 'caozuo',align:"center" },
      ],
      head_modal:false,
      station_header_data:{
        dp_ids:[],
        lct:"1",
        czjb:"0",
        oneNetwork:[],
        secNetwork:[],
        valvePos:[],
        extData:[]
      },
      dpList:[],
      czjb:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
      head_name:1,
      head_modal_type:"添加",
      head_edit_index:"",
      headData:{
        name:"",
        unit:"",
        type:"数值",
        calc:[]
      },
      save_dp_List:[],
      wlwDevList:[],
      station_data: [],
      ext_values:[],
      extList:[],//扩展属性列表
    }
  },
  mounted(){
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.getData();
    this.getDPList();
    this.getExtList();
  },
  methods:{
    getData(){
      var url = "/heating/station-report/info/page?pageNo="+this.pageIndex+'&pageSize='+this.pageSize+'&keyword='+this.keyword;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.myData = res.data.data;
          this.myDataNum = res.data.totalItems
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //分页
    onShowSizeChange(page,pageSize) {
      this.pageIndex = page;
      this.pageSize = pageSize;
      this.myData = [];
      this.myDataNum = 0;
      this.getData();
    },
    search(){
      this.pageIndex = 1;
      this.myData = [];
      this.getData();
    },
    //添加报表
    addReport(){
      this.edit_modal = true;
      this.tantitle = "添加";

    },
    //编辑报表
    showEdit(index){
      this.editData = Object.assign({},this.myData[index])
      this.edit_modal = true;
      this.tantitle = "修改";
    },
    //删除报表
    delFun(index){
      var url = "/heating/station-report/erasure?id="+this.myData[index].station_id;
      this.$axios.delete(url).then((res)=>{
        if(res.status == 200){
          this.$showSuccess("删除成功！");
          this.pageIndex = 1;
          this.getData();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    confirm_save() {
      this.$refs.formM.validateFields().then(() => {
        this.save();
      }, (err) => {
        console.log(err)
      })
    },
    //保存报表
    save(fun){
      var url = "";
      if(this.tantitle == "添加"){
        url = "/heating/station-report";
        var data = JSON.parse(JSON.stringify(this.editData));
        this.$axios.post(url,data).then((res)=>{
          if(res.status == 200){
            this.$showSuccess("保存成功！");
            this.edit_modal = false;
            this.getData();
            if(fun){
              fun()
            }
          }
        }).catch((res)=>{
          console.log(res);
        })
      }
      if(this.tantitle == "修改"){
        url = "/heating/station-report/modify";
        var data = JSON.parse(JSON.stringify(this.editData));
        this.$axios.put(url,data).then((res)=>{
          if(res.status == 200){
            this.$showSuccess("保存成功！");
            this.edit_modal = false;
            this.getData();
            if(fun){
              fun()
            }
          }
        }).catch((res)=>{
          console.log(res);
        })
      }
    },
    /*
    * ==========================  表头 ============================
    * */
    //显示表头
    showSetHead(index){
      this.tantitle = "修改";
      this.report_name = this.myData[index].station_name;
      this.editData = JSON.parse(JSON.stringify(this.myData[index]));
      this.station_header_data = this.editData.station_header_data?JSON.parse(this.editData.station_header_data):{
        dp_ids:[],
        lct:"1",
        czjb:"0",
        oneNetwork:[],
        secNetwork:[],
        valvePos:[],
        extData:[]
      }
      this.station_header_data.extData = this.station_header_data.extData?this.station_header_data.extData:[]
      this.set_head_modal = true;
    },
    //保存表头
    saveSetHead(){
      this.editData.station_header_data = JSON.stringify(this.station_header_data);
      this.save(()=>{
        this.set_head_modal = false;
      })
    },
    //编辑表头名称
    editHead(type,index){
      this.head_modal = true;
      this.head_name = type;
      this.head_modal_type = "修改";
      this.head_edit_index = index;
      if(this.head_name==1){//一次网
        this.headData = Object.assign({},this.station_header_data.oneNetwork[index])
      }
      if(this.head_name==2){//二次网
        this.headData = Object.assign({},this.station_header_data.secNetwork[index])
      }
      if(this.head_name==3){//阀位
        this.headData = Object.assign({},this.station_header_data.valvePos[index])
      }
    },
    //删除表头名称
    delHead(type,index){
      this.head_name = type;
      if(this.head_name==1){//一次网
        this.station_header_data.oneNetwork.splice(index,1)
      }
      if(this.head_name==2){//二次网
        this.station_header_data.secNetwork.splice(index,1)
      }
      if(this.head_name==3){//阀位
        this.station_header_data.valvePos.splice(index,1)
      }
    },
    //添加表头名称
    addHead(index){
      this.head_modal_type = "添加";
      this.head_modal = true;
      this.head_name = index;
      this.headData = {
        name:"",
        unit:"",
        type:"数值",
        calc:[]
      }
    },
    //保存表头名称
    saveHead(){
      if(this.head_name==1){//一次网
        if(this.head_modal_type == "添加"){
          this.station_header_data.oneNetwork.push(this.headData)
        }else{
          this.station_header_data.oneNetwork[this.head_edit_index] = this.headData;
        }
      }
      if(this.head_name==2){//二次网
        if(this.head_modal_type == "添加"){
          this.station_header_data.secNetwork.push(this.headData)
        }else{
          this.station_header_data.secNetwork[this.head_edit_index] = this.headData;
        }
      }
      if(this.head_name==3){//阀位
        if(this.head_modal_type == "添加"){
          this.station_header_data.valvePos.push(this.headData)
        }else{
          this.station_header_data.valvePos[this.head_edit_index] = this.headData;
        }
      }
      this.head_modal = false
    },
    //选择数据类型
    changeTypeFun(){
      delete this.headData.calc;
    },
    /*
    * =========================  表内容 =========================
    * */
    //显示表内容
    showSetContent(index){
      this.tantitle = "修改";
      this.set_content_modal = true;
      this.editData = Object.assign({},this.myData[index]);
      this.report_name = this.editData.station_name;
      this.station_header_data = this.editData.station_header_data?JSON.parse(this.editData.station_header_data):{
        dp_ids:[],
        lct:"1",
        czjb:"0",
        oneNetwork:[],
        secNetwork:[],
        valvePos:[],
        extData:[]
      }
      this.station_header_data.extData = this.station_header_data.extData?this.station_header_data.extData:[];
      this.station_data = this.editData.station_data?JSON.parse(this.editData.station_data):[];
      this.save_dp_List = [];
      this.station_header_data.dp_ids.forEach((item)=>{
        this.wlwDevList.some((val)=>{
          if(item == val.dp_id){
            var oneNetwork = JSON.parse(JSON.stringify(this.station_header_data.oneNetwork));
            var secNetwork = JSON.parse(JSON.stringify(this.station_header_data.secNetwork));
            var valvePos = JSON.parse(JSON.stringify(this.station_header_data.valvePos));
            oneNetwork.forEach((v,k)=>{
              if(v.type == "计算"){
                v.calc = this.getCalcFun(val,k,1);
                v.pp_id = [];
              }else{
                v.pp_id=this.getPP_id(val,k,1)
              }
            })
            secNetwork.forEach((v,k)=>{
              if(v.type == "计算"){
                v.calc = this.getCalcFun(val,k,2);
                v.pp_id = [];
              }else{
                v.pp_id=this.getPP_id(val,k,2)
              }
            })
            valvePos.forEach((v,k)=>{
              if(v.type == "计算"){
                v.calc = this.getCalcFun(val,k,3);
                v.pp_id = [];
              }else{
                v.pp_id=this.getPP_id(val,k,3)
              }
            })

            this.save_dp_List.push({
              device_model_id:val.dp_id,
              dp_name:val.dp_name,
              oneNetwork:JSON.parse(JSON.stringify(oneNetwork)),
              secNetwork:JSON.parse(JSON.stringify(secNetwork)),
              valvePos:JSON.parse(JSON.stringify(valvePos)),
              fenqu:val.fenqu,
              params:val.params
            });
            return true
          }
        })
      })
    },
    getCalcFun(obj,INDEX,type){
      var calc = [];
      this.station_data.some((item)=>{
        if(item.device_model_id == obj.dp_id){
          if(type==1){//一次网
            calc = item.oneNetwork[INDEX]?item.oneNetwork[INDEX].calc:[];
          }
          if(type==2){//二次网
            calc = new Array(obj.fenqu.length)
            if(item.secNetwork[INDEX]){
              item.secNetwork[INDEX].calc.forEach((val,key)=>{
                if(key<calc.length){
                  calc[key] = val
                }
              })
            }
          }
          if(type==3){//阀位
            calc = item.valvePos[INDEX]?item.valvePos[INDEX].calc:[];
          }
          return true
        }
      })
      return calc
    },
    getPP_id(obj,INDEX,type){
      var pp_id = [];
      this.station_data.some((item,index)=>{
        if(item.device_model_id == obj.dp_id){
          if(type==1){//一次网
            pp_id = item.oneNetwork[INDEX]?item.oneNetwork[INDEX].pp_id:[];
          }
          if(type==2){//二次网
            pp_id = new Array(obj.fenqu.length)
            if(item.secNetwork[INDEX]){
              item.secNetwork[INDEX].pp_id.forEach((val,key)=>{
                if(key<pp_id.length){
                  pp_id[key] = val
                }
              })
            }
          }
          if(type==3){//阀位
            pp_id = item.valvePos[INDEX]?item.valvePos[INDEX].pp_id:[];
          }
          return true
        }
      })
      return pp_id
    },
    //保存表内容
    saveSetContent(){
      var data = [];
      this.save_dp_List.forEach((item,index)=>{
        data.push({
          device_model_id:item.device_model_id,
          dp_name:item.dp_name,
          oneNetwork:item.oneNetwork,
          secNetwork:item.secNetwork,
          valvePos:item.valvePos,
        })
      })
      this.editData.station_data = JSON.stringify(data);
      this.save(()=>{
        this.set_content_modal = false;
      })
    },
    //获取型号列表
    getDPList(){
      var url = "/heating/zeiot/device-protocols-options";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.dpList = res.data;
          this.getWLWDev();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取物联网设备
    getWLWDev(){
      var url = "/heating/device-model/easy-info/part";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.wlwDevList = [];
          res.data.forEach((item)=>{
            if(item.device_model_enable){
              this.wlwDevList.push(item);
            }
          })
          this.wlwDevList.forEach((item,index)=>{
            item.fenqu = [];
            item.parts.forEach((val)=>{
              item.fenqu.push(val.device_model_partition_name)
            })
            this.dpList.some((val)=>{
              if(item.device_model_id == val.dp_id){
                item = Object.assign(item,val);
                return true
              }
            })
            this.getModelParam(item.device_model_id,index)
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取型号参数列表
    getModelParam(dp_id,INDEX){
      var url = "/heating/zeiot/device/protocol/para/list?dp_id="+dp_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.wlwDevList[INDEX].params = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterOption(e,option){
      return option.dpp_name.includes(e);
    },
    /*
    * ======================= 扩展属性 ==================
    * */
    //获取系统下设备的扩展属性
    getExtList(){
      var url = '/api/sys/extra-table/type/设备/column?system_id='+this.$cookie.get('user_system_id');
      this.$getZeiotApi(url,"GET","",(res)=>{
        if(res.status == 200){
          this.extList = res.data;
        }
      })
    }

  }
}
</script>

<style scoped>

</style>