<template>
<div class="main_page">
  <main-header :title="tit"></main-header>
  <div class="header_time" style="right: 3rem">
    <i class="iconfont icon-rili"></i>
    <span>{{time}}</span>
  </div>
  <div class="home_btns">
    <div class="def_btn_2" @click="toHome"><setting-outlined /> 系统管理</div>
  </div>
  <div class="left_btns">
    <div class="def_btn_1" @click="toLCT"><swap-outlined />普通模式</div>
  </div>
  <div id="my-three" ref="screenDom" style="width: 100%;height: 100%"></div>
</div>
</template>

<script>
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {TrackballControls} from 'three/examples/jsm/controls/TrackballControls'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
var renderer = null;
var scene = null;
var camera = null;
var loader = null;
var Controls = null;
var controls_key = null;


var BlueFlow_right = null;
var RedFlow_right = null;

var model = null;
var model_lon = null;

var group_label = null;

var mixer = null;
var clock = null;
var ImgDic = {
  "1":""
}
var canvas_obj = {};
export default {
  name: "threeMode4_3",
  data(){
    return {
      tit:"",
      time:"",
      gltfs:[],
      device_id:"",
      realData:{},
    }
  },
  mounted(){
    this.$axios.defaults.headers.common["Authorization"] = "bearer " + this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.device_id = this.$route.query.id;
    this.tit = this.$route.query.name;
    this.time = this.$getTime(new Date()).t3;
    setTimeout(()=>{
      this.time = this.$getTime(new Date()).t3;
    },1000)
    this.initThree();

    group_label = new THREE.Group();
    scene.add(group_label)
    this.getRealData();
    setInterval(()=>{
      this.getRealData();
    },10*1000)
  },
  methods:{
    toHome(){
      var url = this.$router.resolve({
        name:"home",
        query:{
          key:this.$route.query.key
        }
      })
      window.open(url.href,"_blank")
    },
    toLCT(){
      this.$router.push({
        name:"smartHeatingMonitoringSystem",
        query:{
          key:this.$route.query.key,
          name:this.$route.query.name,
          id:this.$route.query.id,
          dp_id:this.$route.query.dp_id,
        }
      })
    },
    initThree() {
      // 创建渲染器
      renderer = new THREE.WebGLRenderer({
        antialias: true,  // 开启抗锯齿
      })
      renderer.setPixelRatio(window.devicePixelRatio)
      var dom = this.$refs.screenDom;
      dom.appendChild(renderer.domElement)  // 将渲染器挂载到dom
      // 设置场景大小
      renderer.setSize(dom.offsetWidth, dom.offsetHeight, true);
      // 渲染器开启阴影效果
      renderer.shadowMap.enabled = true;
      // 设置渲染器的颜色
      renderer.setClearColor('#ffffff')
      renderer.outputEncoding = THREE.sRGBEncoding;

      scene = new THREE.Scene();
      const aspect = dom.offsetWidth / dom.offsetHeight; //宽高可根据实际项目要求更改
      camera = new THREE.PerspectiveCamera(45, aspect, 1, 1000);//使用最常见的远景相机，类似于人眼观察的方式。
      camera.position.set(0, 160, 150);
      camera.lookAt(new THREE.Vector3(0, 0, 0)); // 让相机指向原点
      camera.castShadow = false;
      scene.add(camera);
      Controls = new OrbitControls(camera, renderer.domElement)
      Controls.enableRotate = true;//设置为true表示允许旋转
      //Controls.enabled = false;//禁用拖拽功能，只需要将enabled属性设置为false
      Controls.enableDamping = !0
      Controls.minDistance = 0
      Controls.maxDistance = 500
      Controls.target = new THREE.Vector3(12.33, 6.84, -.89)

      controls_key = new TrackballControls(camera, renderer.domElement);
      controls_key.mouseButtons = {
        //左键平移
        LEFT: THREE.MOUSE.PAN,
        //滚轮滑动
        MIDDLE: THREE.MOUSE.DOLLY,
        //右键旋转
        RIGHT: THREE.MOUSE.ROTATE,
      };
      renderer.render(scene, camera)  // 渲染器渲染场景和相机
      this.setSkyBox();
      this.setModel();
      this.drawWangGe();
      this.setLight();
      this.animate();
    },
    setSkyBox() {
      var src = [
        require("../../assets/images/sky_night/1.png"),
        require("../../assets/images/sky_night/2.png"),
        require("../../assets/images/sky_night/3.png"),
        require("../../assets/images/sky_night/4.png"),
        require("../../assets/images/sky_night/5.png"),
        require("../../assets/images/sky_night/6.png"),
      ]
      scene.background = new THREE.CubeTextureLoader().load(src);
    },
    animate() {
      requestAnimationFrame(this.animate);
      renderer.render(scene, camera);//执行渲染操作
    },
    setModel() {
      clock = new THREE.Clock();
      var _this = this;
      new THREE.MeshPhysicalMaterial({
        color: 0xff0000,
        metalness: 1.0,
        roughness: 0.5
      });
      // 创建GLTF加载器对象
      loader = new GLTFLoader();
      loader.load('./model/ZJ_4_3.glb', (gltf) => {
        // console.log('控制台查看加载gltf文件返回的对象结构',gltf);
        //console.log('gltf对象场景属性',gltf.scene);
        gltf.scene.scale.set(700, 700, 700)  //  设置模型大小缩放
        gltf.scene.position.set(-10, 0, 0)
        let axis = new THREE.Vector3(0, 1, 0);//向量axis
        gltf.scene.rotateOnAxis(axis, Math.PI / 2);
        gltf.scene.traverse(function (object) {
          /*object.material = new THREE.MeshLambertMaterial({
            map: object.material.map, //获取原来材质的颜色贴图属性值
            color: object.material.color, //读取原来材质的颜色
          })*/
          /*object.material = new THREE.MeshPhysicalMaterial({
            //color: 16777215,
            metalness: .5,
            roughness: .3,
            transparent: !0,
            side: THREE.DoubleSide,
          });*/
        })
        model = gltf.scene;
        model_lon = model.clone();
        scene.add(gltf.scene);
        if(gltf.animations.length){
          mixer = new THREE.AnimationMixer( model );
          var action = mixer.clipAction( gltf.animations[ 0 ] );
          /*action.loop = THREE.LoopRepeat; // 循环播放
          action.clampWhenFinished = true; // 动画播放完后停在最后一帧*/
          action.play()
          this.animateFun()
        }
      })
    },
    animateFun() {
      requestAnimationFrame(this.animateFun);
      //TODO:更新轨道控制器
      Controls.update();

      //TODO:更新模型动画
      if(mixer){
        const delta = clock.getDelta();
        mixer.update(delta);
      }
      //TODO:渲染
      renderer.render(scene, camera);
    },
    //画底部网格
    drawWangGe() {
      // 底部网格（可以不设置）
      const gridHelper = new THREE.GridHelper(400, 25, 0x004444, 0x004444);
      scene.add(gridHelper);
    },
    setLight() {
      // 添加环境光（自然光），设置自然光的颜色，设置自然光的强度（0 最暗， 1 最强）
      const ambientLight = new THREE.AmbientLight(0xffffff, 1)
      scene.add(ambientLight);
      // 创建四个平行光源并设置位置
      const light1 = new THREE.DirectionalLight(0xffffff, 1); // 白色光线
      light1.position.set(-2, -5, -7).normalize();
      scene.add(light1);

      const light2 = new THREE.DirectionalLight(0xffffff, 1); // 白色光线
      light2.position.set(2, -5, -7).normalize();
      scene.add(light2);

      const light3 = new THREE.DirectionalLight(0xffffff, 1); // 白色光线
      light3.position.set(0, 1, -7).normalize();
      scene.add(light3);

      const light4 = new THREE.DirectionalLight(0xffffff, 1); // 白色光线
      light4.position.set(0, -5, 7).normalize();
      scene.add(light4);
    },
    //获取实时数据
    getRealData(){
      var url = '/heating/zeiot/device/read-time?id='+this.device_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          res.data.forEach((item)=>{
            this.realData[item.pp_name] = item.pp_value;

          })
          this.LoadImg(()=>{
            //液位
            this.setCard("yewei", "液位", {
              x: -18.071, y: 31.111, z: -46.688, s_x: 14, s_y: 6, ca_w: 260, ca_h: 140
            })
            //一次回水
            this.setCard("yicihuishui", "一次回水", {
              x:-53.294,y:50.667,z:-13.271, s_x:20,s_y:11,ca_w:380,ca_h:200
            });
            //一次供水
            this.setCard("yicigongshui", "一次供水", {
              x:-103.435,y:53.515,z:-7.668, s_x:20,s_y:17,ca_w:380,ca_h:340
            });
            //一次供水温度
            this.setCard("yicigongshui_wd", "一次供水温度", {
              x:-78.078,y:50.775,z:-7.249, s_x:20,s_y:11,ca_w:380,ca_h:200
            });
            this.setGaoQu();
            this.setZhongQu();
            this.setDiQu();
            this.setSiQu();
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //先等图片加载完成  要是不然 图片加载不出来
    LoadImg(fun) {
      let a = new Image;
      a.src = "./images/b3.png";
      a.onload = (() => {
            ImgDic["1"] = a;
            if(fun){
              fun()
            }
          }
      )
    },
    loadCanvas(){
      var d = document.createElement("canvas");
      var c = d.getContext("2d");
      d.width = 260;
      d.height = 140;
      c.scale(1, 1);
      c.drawImage(ImgDic["1"], 0, 0, d.width, d.height);
      c.fillStyle = "rgba(255,255,255,1)";
      c.font = "32px Arial";
      c.fillText("一次供水", 30, 80);
      c.fillText("瞬时流量："+2333+"t/h", 30, 130);
      c.fillText("瞬时热量："+344+"GJ/h", 30, 180);
      c.fillText("累计流量："+6666+"t", 30, 230);
      c.fillText("累计热量："+7777+"GJ", 30, 280);
      var u = new THREE.Texture(d);
      u.needsUpdate = !0;
      let spriteMaterial = new THREE.SpriteMaterial({
        map: u
      });
      var sprite = new THREE.Sprite(spriteMaterial);
      sprite.scale.set(16, 6, 1);
      sprite.position.x = 10; //标签底部箭头和空对象标注点重合
      sprite.position.y = 10; //标签底部箭头和空对象标注点重合
      sprite.position.z = 10; //标签底部箭头和空对象标注点重合
      group_label.add(sprite)
    },
    textFun(c, id) {
      if(id =="yicigongshui"){
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("一次供水", 30, 80);
        c.fillText("瞬时流量："+this.realData['瞬时流量1_1']+"t/h", 30, 130);
        c.fillText("瞬时热量："+this.realData['瞬时热量1_1']+"GJ/h", 30, 180);
        c.fillText("累计流量："+this.realData['累计流量1_1']+"t", 30, 230);
        c.fillText("累计热量："+this.realData['累计热量1_1']+"GJ", 30, 280);
      }
      if(id =="yicigongshui_wd"){
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("一次供水", 30, 80);
        c.fillText("压力："+this.realData['一次供水压力1']+"Mpa", 30, 130);
        c.fillText("温度："+this.realData['温度/供温1_1'] + '℃', 30, 180);
      }
      if (id == "yewei") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("液位：" + this.realData['1#水箱液位'] + 'm', 30, 80);
      }
      if (id == "yicihuishui") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("一次回水", 30, 70);
        c.fillText("压力：" + this.realData['一次回水压力1'] + 'Mpa', 30, 120);
        c.fillText("温度：" + this.realData['压力/回温1_1'] + '℃', 30, 170);
      }
      if (id == "ercigongshui1") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("二次供水", 30, 70);
        c.fillText("压力：" + this.realData['二次供水压力1'] + 'Mpa', 30, 120);
        c.fillText("温度：" + this.realData['二次供水温度1'] + '℃', 30, 170);
      }
      if (id == "ercihuishui1") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("二次回水", 30, 70);
        c.fillText("压力：" + this.realData['二次回水压力1'] + 'Mpa', 30, 120);
        c.fillText("温度：" + this.realData['二次回水温度1'] + '℃', 30, 170);
      }
      if (id == "famenkaidu1") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("阀门开度：" + this.realData['1#调节阀反馈'] + '%', 30, 70);
      }
      if (id == "bushuibeng1") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("1#补水泵：", 30, 70);
        if (this.realData['补水泵运行状态1_1'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "bushuibeng2") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("2#补水泵：", 30, 70);
        if (this.realData['补水泵运行状态1_2'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng1") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("1#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态1_1'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng2") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("2#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态1_2'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng9") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("3#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态1_3'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }

      if (id == "ercigongshui2") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("二次供水", 30, 70);
        c.fillText("压力：" + this.realData['二次供水压力2'] + 'Mpa', 30, 120);
        c.fillText("温度：" + this.realData['二次供水温度2'] + '℃', 30, 170);
      }
      if (id == "ercihuishui2") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("二次回水", 30, 70);
        c.fillText("压力：" + this.realData['二次回水压力2'] + 'Mpa', 30, 120);
        c.fillText("温度：" + this.realData['二次回水温度2'] + '℃', 30, 170);
      }
      if (id == "famenkaidu2") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("阀门开度：" + this.realData['2#调节阀反馈'] + '%', 30, 70);
      }
      if (id == "bushuibeng3") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("1#补水泵：", 30, 70);
        if (this.realData['补水泵运行状态2_1'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "bushuibeng4") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("2#补水泵：", 30, 70);
        if (this.realData['补水泵运行状态2_2'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng3") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("1#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态2_1'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng4") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("2#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态2_2'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng10") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("3#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态2_3'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }

      if (id == "ercigongshui3") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("二次供水", 30, 70);
        c.fillText("压力：" + this.realData['二次供水压力3'] + 'Mpa', 30, 120);
        c.fillText("温度：" + this.realData['二次供水温度3'] + '℃', 30, 170);
      }
      if (id == "ercihuishui3") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("二次回水", 30, 70);
        c.fillText("压力：" + this.realData['二次回水压力3'] + 'Mpa', 30, 120);
        c.fillText("温度：" + this.realData['二次回水温度3'] + '℃', 30, 170);
      }
      if (id == "famenkaidu3") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("阀门开度：" + this.realData['3#调节阀反馈'] + '%', 30, 70);
      }
      if (id == "bushuibeng5") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("1#补水泵：", 30, 70);
        if (this.realData['补水泵运行状态3_1'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "bushuibeng6") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("2#补水泵：", 30, 70);
        if (this.realData['补水泵运行状态3_2'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng5") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("1#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态3_1'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng6") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("2#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态3_2'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng11") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("3#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态3_3'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }

      if (id == "ercigongshui4") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("二次供水", 30, 70);
        c.fillText("压力：" + this.realData['二次供水压力4'] + 'Mpa', 30, 120);
        c.fillText("温度：" + this.realData['二次供水温度4'] + '℃', 30, 170);
      }
      if (id == "ercihuishui4") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("二次回水", 30, 70);
        c.fillText("压力：" + this.realData['二次回水压力4'] + 'Mpa', 30, 120);
        c.fillText("温度：" + this.realData['二次回水温度4'] + '℃', 30, 170);
      }
      if (id == "famenkaidu4") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("阀门开度：" + this.realData['4#调节阀反馈'] + '%', 30, 70);
      }
      if (id == "bushuibeng7") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("1#补水泵：", 30, 70);
        if (this.realData['补水泵运行状态4_1'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "bushuibeng8") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("2#补水泵：", 30, 70);
        if (this.realData['补水泵运行状态4_2'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng7") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("1#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态4_1'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng8") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("2#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态4_2'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      if (id == "xunhuanbeng12") {
        c.fillStyle = "rgba(255,255,255,1)";
        c.font = "32px Arial";
        c.fillText("3#循环泵：", 30, 70);
        if (this.realData['循环泵运行状态4_3'] == 1) {
          c.fillStyle = "#00fc00";
          c.fillText("启动", 190, 70)
        } else {
          c.fillStyle = "#ff0000";
          c.fillText("停止", 190, 70)
        }
      }
      return c
    },
    setCard(el_id,id,obj){

      var d = document.createElement("canvas");
      var c = d.getContext("2d");
      d.width = obj.ca_w;
      d.height = obj.ca_h;
      c.scale(1, 1);

      // 重新绘制背景
      c.clearRect(0, 0, d.width, d.height);
      c.drawImage(ImgDic["1"], 0, 0, d.width, d.height);
      c = this.textFun(c,el_id)
      var u = new THREE.Texture(d);
      u.needsUpdate = !0;
      let spriteMaterial = new THREE.SpriteMaterial({
        map: u
      });
      if(canvas_obj[el_id]){
        canvas_obj[el_id].material = spriteMaterial;
      }else{
        var sprite = new THREE.Sprite(spriteMaterial);
        sprite.scale.set(obj.s_x, obj.s_y, 1);
        sprite.position.x = obj.x; //标签底部箭头和空对象标注点重合
        sprite.position.y = obj.y; //标签底部箭头和空对象标注点重合
        sprite.position.z = obj.z; //标签底部箭头和空对象标注点重合
        group_label.add(sprite);
        canvas_obj[el_id] = sprite;
      }
    },
    setGaoQu(){
      //高区 二次供水
      this.setCard("ercigongshui1", "二次供水1", {
        x: -90.919, y: 40.638, z: 4.301, s_x: 18, s_y: 11, ca_w: 300, ca_h: 200
      })
      //高区 二次回水
      this.setCard("ercihuishui1", "二次回水1", {
        x: -65.014,y: 13,z: 55.916, s_x:20,s_y:11,ca_w:380,ca_h:200
      })
      //高区 阀门开度
      this.setCard("famenkaidu1", "1#调节阀反馈", {
        x: -83.883, y: 46, z: 4.195, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //高区 1#补水泵
      this.setCard("bushuibeng1", "补水泵运行状态1_1", {
        x: -94.955, y: 40, z: 35.255,s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //高区 2#补水泵
      this.setCard("bushuibeng2", "补水泵运行状态1_2", {
        x: -88.132, y: 40, z: 35.765, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //高区 1#循环泵
      this.setCard("xunhuanbeng1", "循环泵运行状态1_1", {
        x: -76.944, y: 40, z: 35.003, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //高区 2#循环泵
      this.setCard("xunhuanbeng2", "循环泵运行状态1_2", {
        x: -63.986, y: 40, z: 35.567, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      });
      //高区 3#循环泵
      this.setCard("xunhuanbeng9", "循环泵运行状态1_3", {
        x: -58.986, y: 40, z: 35.567, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      });
    },
    setZhongQu() {
      //高区 二次供水
      this.setCard("ercigongshui2", "二次供水2", {
        x: -20.562, y: 40, z: 6.305, s_x: 18, s_y: 11, ca_w: 300, ca_h: 200
      })
      //高区 二次回水
      this.setCard("ercihuishui2", "二次回水2", {
        x: 5.193,y: 13,z: 55.238, s_x:20,s_y:11,ca_w:380,ca_h:200
      })
      //中区 阀门开度
      this.setCard("famenkaidu2", "2#调节阀反馈", {
        x: -15.428, y: 46, z: 5.255, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //中区 1#补水泵
      this.setCard("bushuibeng3", "补水泵运行状态2_1", {
        x: -22.754, y: 40, z: 37.115, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //中区 2#补水泵
      this.setCard("bushuibeng4", "补水泵运行状态2_2", {
        x: -16.234, y: 40, z: 38.006, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //中区 1#循环泵
      this.setCard("xunhuanbeng3", "循环泵运行状态2_1", {
        x: -4.024, y: 40, z: 36.239, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //中区 2#循环泵
      this.setCard("xunhuanbeng4", "循环泵运行状态2_2", {
        x: 5.530, y: 40, z: 35.879, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      });
      //中区 3#循环泵
      this.setCard("xunhuanbeng10", "循环泵运行状态2_3", {
        x: 9.530, y: 40, z: 35.879, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      });
    },
    setDiQu() {
      //低区 二次供水
      this.setCard("ercigongshui3", "二次供水3", {
        x: 47.562, y: 40, z: 6.305, s_x: 18, s_y: 11, ca_w: 300, ca_h: 200
      })
      //低区 二次回水
      this.setCard("ercihuishui3", "二次回水3", {
        x: 76.193,y: 13,z: 55.238, s_x:20,s_y:11,ca_w:380,ca_h:200
      })
      //低区 阀门开度
      this.setCard("famenkaidu3", "3#调节阀反馈", {
        x: 58.428, y: 46, z: 5.255, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //低区 1#补水泵
      this.setCard("bushuibeng5", "补水泵运行状态3_1", {
        x: 41.754, y: 40, z: 37.115, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //低区 2#补水泵
      this.setCard("bushuibeng6", "补水泵运行状态3_2", {
        x: 51.234, y: 40, z: 38.006, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //低区 1#循环泵
      this.setCard("xunhuanbeng5", "循环泵运行状态3_1", {
        x: 65.024, y: 40, z: 31.239, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //低区 2#循环泵
      this.setCard("xunhuanbeng6", "循环泵运行状态3_2", {
        x: 75.530, y: 40, z: 31.879, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      });
      this.setCard("xunhuanbeng11", "循环泵运行状态3_3", {
        x: 81.530, y: 40, z: 31.879, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      });
    },
    setSiQu() {
      //四区 二次供水
      this.setCard("ercigongshui4", "二次供水4", {
        x: 118.562, y: 40, z: 6.305, s_x: 18, s_y: 11, ca_w: 300, ca_h: 200
      })
      //四区 二次回水
      this.setCard("ercihuishui4", "二次回水4", {
        x: 145.193,y: 13,z: 55.238, s_x:20,s_y:11,ca_w:380,ca_h:200
      })
      //四区 阀门开度
      this.setCard("famenkaidu4", "4#调节阀反馈", {
        x: 127.428, y: 46, z: 5.255, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //四区 1#补水泵
      this.setCard("bushuibeng7", "补水泵运行状态4_1", {
        x: 115.754, y: 40, z: 37.115, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //四区 2#补水泵
      this.setCard("bushuibeng8", "补水泵运行状态4_2", {
        x: 124.234, y: 40, z: 38.006, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //四区 1#循环泵
      this.setCard("xunhuanbeng7", "循环泵运行状态4_1", {
        x: 138.024, y: 40, z: 31.239, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      })
      //四区 2#循环泵
      this.setCard("xunhuanbeng8", "循环泵运行状态4_2", {
        x: 148.530, y: 40, z: 31.879, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      });
      //四区 3#循环泵
      this.setCard("xunhuanbeng12", "循环泵运行状态4_3", {
        x: 154.530, y: 40, z: 31.879, s_x: 16, s_y: 6, ca_w: 290, ca_h: 120
      });
    },
  }
}
</script>

<style scoped>

</style>