<template>
  <div class="homePage">
    <a-layout class="homePageCont">
      <a-layout-header class="homeHeader" >
<!--        <img src="../assets/images/logo.png" class="homeLogo">-->
        <span class="homeName">智慧供热</span>
        <a-dropdown class="top_menu">
          <a class="ant-dropdown-link" @click.prevent>
            <UserOutlined />
            {{ account }}
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a href="javascript:;" @click="logout">退出登录</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-layout-header>
      <a-layout class="homeCont">
        <a-layout-sider :class='{"homeLeft":true}'  v-model:collapsed="collapsed" collapsible >
          <a-menu
              v-model:selectedKeys="menuSelectKeys"
              v-model:openKeys="menuOpenKeys"
              theme="dark" mode="inline"
              :style="{ height: '100%', borderRight: 0 }"
              @select="selectFun"
          >
            <template v-for="(item,index) in menuList" >
              <a-menu-item :key="item.id" v-if="item.child.length<=0" @click="toPage(index)">
                <template #icon>
                  <setting-outlined v-if="item.id == 'basicConfig'" />
                  <home-outlined v-if="item.id == 'screenConfig'" />
                  <branches-outlined v-if="item.id == 'drawPipeline'" />
                  <table-outlined  v-if="item.id == 'report'"/>
                  <database-outlined v-if="item.id == 'ioTDevices'"/>
                  <block-outlined v-if="item.id == 'bindHeatStations'" />
                  <appstore-outlined v-if="item.id == 'unitValveManagement'"  />
                  <border-outlined v-if="item.id == 'houseValveManagement'" />
                </template>
                {{item.name}}
              </a-menu-item>
            </template>

          </a-menu>
        </a-layout-sider>
        <a-layout-content >
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
export default {
  name: "home",
  data(){
    return{
      menuList:[
        {name:'基础配置',path:'basicConfig',id:"basicConfig",child:[]},
        {name:'首页配置',path:'screenConfig',id:"screenConfig",child:[]},
        {name:'物联网设备',path:'ioTDevices',id:"ioTDevices",child:[]},
        {name:'地图管线绘制',path:'drawPipeline',id:"drawPipeline",child:[]},
        {name:'报表管理',path:'report',id:"report",child:[]},
        {name:'换热站绑定',path:'bindHeatStations',id:"bindHeatStations",child:[]},
        {name:'单元阀管理',path:'unitValveManagement',id:"unitValveManagement",child:[]},
        {name:'户阀管理',path:'houseValveManagement',id:"houseValveManagement",child:[]},
      ],
      menuSelectKeys:["basicConfig"],
      menuOpenKeys:["basicConfig"],
      collapsed:false,
      next_key:"basicConfig",
      account:""
    }
  },
  mounted(){
    this.account = this.$cookie.get("user_account");

  },
  methods:{
    showHideFun(){
      this.collapsed = !this.collapsed
    },
    toPage(index){
      if(this.menuList[index].path == "screenConfig"){
        var url = this.$router.resolve({
          name:this.menuList[index].path,
          query:{
            key:this.$route.query.key
          }
        })
        window.open(url.href,"_blank");

      }else{
        this.$router.push({
          name:this.menuList[index].path,
          query:{
            key:this.$route.query.key
          }
        })
      }
    },
    selectFun(e){
      console.log("opopopop")
      if(e.key == "jiankongdian"){
        this.menuSelectKeys = [this.next_key]
      }else{
        this.next_key = this.menuSelectKeys[0]
      }
    },
    logout(){
      this.$router.push({
        name:"login"
      })
      var cookies = this.$cookie.get();
      for(var key in cookies){
        this.$cookie.remove(key);
      }
    },

  }
}
</script>
<style>
.ant-dropdown .ant-dropdown-menu{
  background-color:#ffffff ;
}
.ant-dropdown .ant-dropdown-menu-title-content{
  color: #333333;
}
.ant-dropdown .ant-dropdown-menu-title-content:hover{
  color: #333333;
}
</style>

<style lang="less">
@import "@/assets/css/home.less";

</style>